<template>
  <div class="main-router-view">
    <!-- 标题 -->
    <a-breadcrumb class="title-breadcrumb">
      <a-breadcrumb-item>系统设置</a-breadcrumb-item>
      <a-breadcrumb-item>运营中心权限</a-breadcrumb-item>
    </a-breadcrumb>
    <!-- 页面 -->
    <PermissionView :source="source"></PermissionView>
  </div>
</template>

<script>
import PermissionView from './components/PermissionView'
export default {
  components: {
    PermissionView
  },
  data () {
    return {
      source: 'project'
    }
  }
}
</script>

<style scoped>
.main-router-view {
  position: relative;
  background-color: #fff;
  height: 100%;
}
.title-breadcrumb {
  padding: 15px 25px 0px 25px;
}
</style>
